import { firestoreConnect } from 'react-redux-firebase';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import { findCompanyFromCompanyCode, findCompanyFromCity } from '../../actions/companyActions';
import { ThermageButton } from '../Buttons/thermageButton';
import Hidden from '@material-ui/core/Hidden';

const styles = {
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 2,
    textAlign: 'left',
    color: '#000',
  },
  alignCenter: {
    textAlign: 'center',
  },
  textField: {
    width: '100%',
  },
  mobileCenter: {
    '@media (max-width: 599px)': {
      textAlign: 'center',
    },
  },
};

const INITIAL_STATE = {
  city: '',
  clinicCode: '',
  pagePromo: 'olff8ViVEXKmuBK179Zs', //This is the id of the promo, this will change to load from the url once we have more then one promo
};

class SelectDoctor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
  }

  componentWillUnmount() {}

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  searchforClinicByCity = (event) => {
    event.preventDefault();
    this.props.findCompanyFromCity({ city: this.state.city, promo: this.state.pagePromo });
  };

  searchForClinicByClinicCode = (event) => {
    event.preventDefault();
    this.props.findCompanyFromCompanyCode({ code: this.state.clinicCode, promo: this.state.pagePromo });
  };

  render() {
    const { city, clinicCode } = this.state;

    const { t, classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid container spacing={4} alignItems='center' justify='center'>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems='center' justify='center'>
              <Grid item xs={12}>
                <Typography variant='h6' align='center'>
                  {t('home.pleaseSelectTwo')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={5}>
                <form onSubmit={this.searchforClinicByCity}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant='h6' className={classes.mobileCenter}>
                        {t('home.findaClinicTitle')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField id='city' className={classes.textField} name='city' value={city} onChange={this.onChange} label={t('home.citySearch')} />
                    </Grid>
                    <Grid item xs={12} className={classes.mobileCenter}>
                      <ThermageButton variant='contained' color='primary' className={classes.margin} onClick={this.searchforClinicByCity}>
                        {t('buttons.find')}
                      </ThermageButton>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
              <Hidden xsDown>
                <Grid item sm={2} align='center'>
                  <Divider orientation='vertical' />
                </Grid>
              </Hidden>
              <Hidden smUp>
                <Grid item container spacing={2} xs={12} alignContent='center' alignItems='center'>
                  <Grid item xs={4}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4} align='center'>
                    <Typography variant='h6'>{t('signIn.or')}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Divider />
                  </Grid>
                </Grid>
              </Hidden>
              <Grid item xs={12} sm={5}>
                <form onSubmit={this.searchForClinicByClinicCode}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant='h6' className={classes.mobileCenter}>
                        {t('home.clinicCodeTitle')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField id='clinicCode' className={classes.textField} name='clinicCode' value={clinicCode} onChange={this.onChange} label={t('home.clinicCodeSearch')} />
                    </Grid>
                    <Grid item xs={12} className={classes.mobileCenter}>
                      <ThermageButton variant='contained' color='primary' onClick={this.searchForClinicByClinicCode} className={classes.margin}>
                        {t('buttons.start')}
                      </ThermageButton>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

SelectDoctor.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  //console.log('this is my state', state)
  return {
    //auth: state.firebase.auth,
    user: state.firebase.profile,
    companyError: state.company.companyError,
    companyMessage: state.company.companyMessage,
    //selectedCompany: state.company.company
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    findCompanyFromCompanyCode: ({ code, promo }) => dispatch(findCompanyFromCompanyCode({ code, promo })),
    findCompanyFromCity: ({ city, promo }) => dispatch(findCompanyFromCity({ city, promo })),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(),
  firestoreConnect((props) => [
    //{ collection: 'consumer', doc: props.match.params.id }
  ])
)(SelectDoctor);
