import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { makeStyles } from '@material-ui/core/styles';
import Logo from '../../images/solta_logo.svg';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

const HeaderStyles = makeStyles((theme) => ({
  logo: {
    //marginTop: 30,
    marginRight: 30,
    width: 150,
    '@media (max-width: 1025px)': {
      width: 100,
      //marginTop: 26
    },
    textAlign: 'right',
  },
  title: {
    //fontFamily: 'Montserrat',
    fontWeight: 300,
    marginLeft: 40,
    color: '#656366',
    //fontSize: 36,
    //'@media (max-width: 1025px)': {
    //  fontSize: 28,
    //},
  },
  logoHeader: {
    backgroundColor: '#fff',
    marginTop: 30,
    //marginBottom: 30,
  },
  textRight: {
    paddingTop: 30,
    textAlign: 'right',
  },
  purpleLine: {
    background: 'linear-gradient(90deg, rgba(67,22,63,1) 0%, rgba(155,62,150,1) 100%)',
    display: 'block',
    height: 10,
  },
  purpleGradient: {
    //background: 'rgb(255,255,255)',
    background: 'linear-gradient(0deg, rgba(255,255,255,0.45) 0%, rgba(204,156,201,0.45) 100%)',
    height: 60,
    display: 'block',
  },
}));

const LogoHeader = ({ t }) => {
  const classes = HeaderStyles();

  return (
    <div className={classes.logoHeader}>
      <div className=''>
        <div className={'row logo-strip'}>
          <div className='col s12 '>
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs={8}>
                <Link to={ROUTES.HOME}>
                  <Typography variant='h4' className={classes.title}>
                    {t('header.title')}
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={4} align='right'>
                <img src={Logo} alt='Solta' className={classes.logo} />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <Grid container spacing={0} alignItems='center'>
        <Grid item xs={12}>
          <Paper square elevation={0} className={classes.purpleLine}></Paper>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Paper square elevation={0} className={classes.purpleGradient}></Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default LogoHeader;
