export const signIn = (credentials) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase.analytics().logEvent('login', { method: 'Email' });

    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        dispatch({ type: 'LOGIN_SUCCESS' });
      })
      .catch((err) => {
        dispatch({ type: 'LOGIN_ERROR', err });
      });
  };
};

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: 'SIGNOUT_SUCCESS' });
      });
  };
};

export const closeAuthPopUps = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'CLOSE_AUTH_POPUPS' });
  };
};

export const sendForgottenPasswordEmail = (email) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        dispatch({ type: 'PASSWORD_RESET_SENT' });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_SENDING_PASSWORD_RESET', err });
      });
  };
};

export const createEmailUser = (newUser) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firebase.analytics().logEvent('sign_up', { method: 'Email' });

    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.emailAddress, newUser.password)
      .then((userResp) => {
        return firestore
          .collection('promoCodes')
          .doc('freeCodes')
          .get()
          .then((promoCodeResp) => {
            const promoCode = promoCodeResp.data().codes[0];

            return firestore
              .collection('consumer')
              .doc(userResp.user.uid)
              .set({
                firstName: newUser.firstName,
                lastName: newUser.lastName,
                fullName: newUser.firstName + ' ' + newUser.lastName,
                initials: newUser.firstName[0] + newUser.lastName[0],
                promos: {
                  olff8ViVEXKmuBK179Zs: {
                    promoCode: promoCode,
                    promoCodeClaimed: false,
                    clinic: '',
                  },
                }, //This is just going to be the first round this will not be set till they set later once we get more promos going
                email: newUser.emailAddress,
                role: 'consumer',
                userAdded: new Date(),
              })
              .then((r) => {
                const unusedPromoCodes = promoCodeResp.data().codes.filter((code) => code !== promoCode);

                return firestore
                  .collection('promoCodes')
                  .doc('freeCodes')
                  .set({
                    codes: unusedPromoCodes,
                  })
                  .then((r) => {
                    return firestore
                      .collection('promoCodes')
                      .doc('promoCodesLeft')
                      .update({
                        count: firestore.FieldValue.increment(-1),
                      })
                      .then((r) => {
                        return firestore
                          .collection('promoCodes')
                          .doc('usedCodes')
                          .update({
                            codes: firestore.FieldValue.arrayUnion(promoCode),
                          })
                          .then((r) => {
                            dispatch({ type: 'NEW_USER_CREATED' });
                          });
                      });
                  });
              });
          });
      })
      .catch((err) => {
        dispatch({ type: 'NEW_USER_ERROR', err });
      });
  };
};

export const updateUserInfoDB = (user) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection('consumer')
      .doc(user.id)
      .update({
        firstName: user.firstName,
        lastName: user.lastName,
        fullName: user.firstName + ' ' + user.lastName,
        initials: user.firstName[0] + user.lastName[0],
        company: '',
        role: 'consumer',
      })
      .then(() => {
        dispatch({ type: 'USER_DB_INFO_UPDATED' });
      })
      .catch((err) => {
        dispatch({ type: 'USER_DB_INFO_ERROR', err });
      });
  };
};

export const updateUserPassword = (user) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();

    const currentUser = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(user.email, user.currentPassword);
    currentUser
      .reauthenticateWithCredential(credential)
      .then(() => {
        currentUser
          .updatePassword(user.newPassword)
          .then(() => {
            dispatch({ type: 'USER_PASSWORD_WAS_UPDATED' });
          })
          .catch((err) => {
            dispatch({ type: 'USER_PASSWORD_WAS_NOT_UPDATED', err });
          });
      })
      .catch((err) => {
        dispatch({ type: 'CHANGE_PASSWORD_REAUTHENTICATION_ERROR', err });
      });
  };
};

export const signUpWithFacebook = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firebase.analytics().logEvent('sign_up', { method: 'Facebook' });

    let provider = new firebase.auth.FacebookAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        //let token = result.credential.accessToken;
        let user = result.user;
        //console.log('this is my user', result.user);
        return firestore
          .collection('promoCodes')
          .doc('freeCodes')
          .get()
          .then((promoCodeResp) => {
            const promoCode = promoCodeResp.data().codes[0];

            return firestore
              .collection('consumer')
              .doc(user.uid)
              .set({
                firstName: user.displayName.substr(0, user.displayName.indexOf(' ')),
                lastName: user.displayName.substr(user.displayName.indexOf(' ') + 1),
                fullName: user.displayName,
                initials: user.displayName.substr(0, user.displayName.indexOf(' '))[0] + user.displayName.substr(user.displayName.indexOf(' ') + 1)[0],
                promos: {
                  olff8ViVEXKmuBK179Zs: {
                    promoCode: promoCode,
                    promoCodeClaimed: false,
                    clinic: '',
                  },
                }, //This is just going to be the first round this will not be set till they set later once we get more promos going
                email: user.email,
                role: 'consumer',
                userAdded: new Date(),
              })
              .then((r) => {
                const unusedPromoCodes = promoCodeResp.data().codes.filter((code) => code !== promoCode);

                return firestore
                  .collection('promoCodes')
                  .doc('freeCodes')
                  .set({
                    codes: unusedPromoCodes,
                  })
                  .then((r) => {
                    return firestore
                      .collection('promoCodes')
                      .doc('promoCodesLeft')
                      .update({
                        count: firestore.FieldValue.increment(-1),
                      })
                      .then((r) => {
                        return firestore
                          .collection('promoCodes')
                          .doc('usedCodes')
                          .update({
                            codes: firestore.FieldValue.arrayUnion(promoCode),
                          })
                          .then((r) => {
                            dispatch({ type: 'NEW_FACEBOOK_USER_CREATED' });
                          });
                      });
                  });
              });
          });
      })
      .catch((err) => {
        dispatch({ type: 'FACEBOOK_LOGIN_ERROR', err });
      });
  };
};

export const signUpWithGoogle = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    firebase.analytics().logEvent('sign_up', { method: 'Google' });
    let provider = new firebase.auth.GoogleAuthProvider();
    //console.log('we are creating a new user!');
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        //console.log('did we even get a result?', result);
        //let token = result.credential.accessToken;
        let user = result.user;
        //console.log('this is my user', result.user);
        return firestore
          .collection('promoCodes')
          .doc('freeCodes')
          .get()
          .then((promoCodeResp) => {
            const promoCode = promoCodeResp.data().codes[0];

            return firestore
              .collection('consumer')
              .doc(user.uid)
              .set({
                firstName: user.displayName.substr(0, user.displayName.indexOf(' ')),
                lastName: user.displayName.substr(user.displayName.indexOf(' ') + 1),
                fullName: user.displayName,
                initials: user.displayName.substr(0, user.displayName.indexOf(' '))[0] + user.displayName.substr(user.displayName.indexOf(' ') + 1)[0],
                promos: {
                  olff8ViVEXKmuBK179Zs: {
                    promoCode: promoCode,
                    promoCodeClaimed: false,
                    clinic: '',
                  },
                }, //This is just going to be the first round this will not be set till they set later once we get more promos going
                email: user.email,
                role: 'consumer',
                userAdded: new Date(),
              })
              .then((r) => {
                const unusedPromoCodes = promoCodeResp.data().codes.filter((code) => code !== promoCode);

                return firestore
                  .collection('promoCodes')
                  .doc('freeCodes')
                  .set({
                    codes: unusedPromoCodes,
                  })
                  .then((r) => {
                    return firestore
                      .collection('promoCodes')
                      .doc('promoCodesLeft')
                      .update({
                        count: firestore.FieldValue.increment(-1),
                      })
                      .then((r) => {
                        return firestore
                          .collection('promoCodes')
                          .doc('usedCodes')
                          .update({
                            codes: firestore.FieldValue.arrayUnion(promoCode),
                          })
                          .then((r) => {
                            dispatch({ type: 'NEW_GOOGLE_USER_CREATED' });
                          });
                      });
                  });
              });
          });
      })
      .catch((err) => {
        //console.log('we have an error', err);
        dispatch({ type: 'GOOGLE_LOGIN_ERROR', err });
      });
  };
};

export const signInFacebook = (credentials) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase.analytics().logEvent('login', { method: 'Facebook' });
    let provider = new firebase.auth.FacebookAuthProvider();

    firebase
      .auth()
      .signInWithRedirect(provider)
      .then(() => {
        dispatch({ type: 'LOGIN_SUCCESS' });
      })
      .catch((err) => {
        dispatch({ type: 'LOGIN_ERROR', err });
      });
  };
};

export const signInGoogle = (credentials) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase.analytics().logEvent('login', { method: 'Google' });
    let provider = new firebase.auth.GoogleAuthProvider();

    firebase.auth().signInWithRedirect(provider);
    dispatch({ type: 'LOGIN_SUCCESS' });
  };
};

export const registerLoggedinUsers = (user) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    //console.log('this is my userID', user);
    //first check to see if the user is has a user account
    firestore
      .collection('users')
      .doc(user.uid)
      .get()
      .then((snapshot) => {
        //If it is empty then we check the comsumer accounts
        if (!snapshot.exists) {
          firestore
            .collection('consumer')
            .doc(user.uid)
            .get()
            .then((snapshot) => {
              //If they are not in the consumer we will then create them as a new user
              if (!snapshot.exists) {
                return firestore
                  .collection('promoCodes')
                  .doc('freeCodes')
                  .get()
                  .then((promoCodeResp) => {
                    const promoCode = promoCodeResp.data().codes[0];

                    return firestore
                      .collection('consumer')
                      .doc(user.uid)
                      .set({
                        firstName: user.displayName.substr(0, user.displayName.indexOf(' ')),
                        lastName: user.displayName.substr(user.displayName.indexOf(' ') + 1),
                        fullName: user.displayName,
                        initials: user.displayName.substr(0, user.displayName.indexOf(' '))[0] + user.displayName.substr(user.displayName.indexOf(' ') + 1)[0],
                        promos: {
                          olff8ViVEXKmuBK179Zs: {
                            promoCode: promoCode,
                            promoCodeClaimed: false,
                            clinic: '',
                          },
                        }, //This is just going to be the first round this will not be set till they set later once we get more promos going
                        email: user.email,
                        role: 'consumer',
                        userAdded: new Date(),
                      })
                      .then((r) => {
                        const unusedPromoCodes = promoCodeResp.data().codes.filter((code) => code !== promoCode);

                        return firestore
                          .collection('promoCodes')
                          .doc('freeCodes')
                          .set({
                            codes: unusedPromoCodes,
                          })
                          .then((r) => {
                            return firestore
                              .collection('promoCodes')
                              .doc('promoCodesLeft')
                              .update({
                                count: firestore.FieldValue.increment(-1),
                              })
                              .then((r) => {
                                return firestore
                                  .collection('promoCodes')
                                  .doc('usedCodes')
                                  .update({
                                    codes: firestore.FieldValue.arrayUnion(promoCode),
                                  })
                                  .then((r) => {
                                    dispatch({ type: 'NEW_GOOGLE_USER_CREATED' });
                                  });
                              });
                          });
                      });
                  });
              } else {
                dispatch({ type: 'USER_IS_CLINIC' });
              }
            });
        } else {
          dispatch({ type: 'USER_IS_CLINIC' });
        }
        //console.log('this is my snapshot', snapshot);
      });
  };
};
