import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import HomePage from '../Home';
import LoginPage from '../SignIn';
import SignUp from '../SignUp';
import FaqPage from '../Faq';
import TermsPage from '../Terms';
import ThermageEyePage from '../About/thermageEyePage';
import ClinicCodeLandingPage from '../Clinics/clinicCodeLanding';
import ForgotPasswordForm from '../SignIn/forgotPassword';
import ProfilePage from '../Profile';

import * as ROUTES from '../../constants/routes';

const App = () => (
  <Router>
    <div>
      <Route exact path={ROUTES.LANDING} component={HomePage} />
      <Route exact path={ROUTES.SIGN_UP} component={SignUp} />
      <Route exact path={ROUTES.SIGN_UP_REDIRECT} component={SignUp} />
      <Route exact path={ROUTES.LOGIN} component={LoginPage} />
      <Route exact path={ROUTES.LOGIN_REDIRECT} component={LoginPage} />
      <Route exact path={ROUTES.FAQ} component={FaqPage} />
      <Route exact path={ROUTES.TERMS} component={TermsPage} />
      <Route exact path={ROUTES.ABOUT} component={ThermageEyePage} />
      <Route exact path={ROUTES.CLINICS} component={ClinicCodeLandingPage} />
      <Route exact path={ROUTES.FORGOTTEN_PASSWORD} component={ForgotPasswordForm} />
      <Route exact path={ROUTES.PROFILE} component={ProfilePage} />
    </div>
  </Router>
);

export default App;
