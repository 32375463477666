import { firestoreConnect } from 'react-redux-firebase';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { sendContactEmail, removeClinicToMyPromo, closePromosPopUpMess } from '../../actions/promoActions';
import { ThermageButton } from '../Buttons/thermageButton';
import LoadingBlock from '../Loading';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
//import { Link } from 'react-router-dom';

const styles = {
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 2,
    textAlign: 'left',
    color: '#000',
  },

  alignCenter: {
    textAlign: 'center',
  },
  mobileCenter: {
    '@media (max-width: 599px)': {
      textAlign: 'center',
    },
  },
  anotherClinic: {
    opacity: '0.25',
  },
  purple: {
    color: '#9b3e96',
  },
  spacingTop: {
    marginTop: 20,
  },
};

const INITIAL_STATE = {
  //pagePromo: 'olff8ViVEXKmuBK179Zs' //This is the id of the promo, this will change to load from the url once we have more then one promo
};

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

class ClinicBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
  }

  componentWillUnmount() {}

  sendEmailToClinic = () => {
    //console.log('We will program an email to go out to both parties', this.props.company[0].id);
    this.props.sendContactEmail({ userID: this.props.auth.uid, promoID: this.props.promoID, clinicID: this.props.company[0].id, user: this.props.user, company: this.props.company[0] });
  };

  pickAnotherClinic = () => {
    this.props.removeClinicToMyPromo({ userID: this.props.auth.uid, promoID: this.props.promoID });
  };

  closePopUps = () => {
    this.props.closePromosPopUpMess();
  };

  render() {
    const { t, classes, company, promoErrorPopUp, promoError, promoMessage, promoPopUp, user, promoID } = this.props;

    //console.log('this is my user', user.promos[promoID].promoCodeClaimed);
    if (user) {
      let promoClaimed = user.promos[promoID].promoCodeClaimed;

      if (company) {
        return (
          <div className={classes.root}>
            <Grid container spacing={2} justify='center'>
              <Grid item xs={12} align='center' className={classes.spacingTop}>
                <Typography variant='body1'>{promoClaimed ? t('clinicBlocks.promoClaimed') : t('clinicBlocks.pleaseShow')}</Typography>
              </Grid>
              <Grid item xs={2} align='center' className={classes.spacingTop}>
                <Divider />
              </Grid>
              <Grid item xs={12} align='center'>
                <Typography variant='h4' className={classes.purple}>
                  {company[0].name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container item spacing={2}>
                  <Grid item sm={2} xs={12} className={classes.mobileCenter}>
                    <Typography variant='body1'>
                      <b>{t('clinicBlocks.location')}</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={10} xs={12} className={classes.mobileCenter}>
                    <Typography variant='body1'>
                      {company[0].location.streetAddress}
                      {company[0].location.streetAddress2}
                    </Typography>
                  </Grid>
                  <Grid item sm={2} xs={12} className={classes.mobileCenter}>
                    <Typography variant='body1'>
                      <b>{t('clinicBlocks.city')}</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={10} xs={12} className={classes.mobileCenter}>
                    <Typography variant='body1'>{company[0].location.city}</Typography>
                  </Grid>
                  <Grid item sm={2} xs={12} className={classes.mobileCenter}>
                    <Typography variant='body1'>
                      <b>{t('clinicBlocks.phone')}</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={10} xs={12} className={classes.mobileCenter}>
                    <Typography variant='body1'>
                      <a href={`tel://${company[0].phoneNumber}`} target='_blank' rel='noopener noreferrer'>
                        {company[0].phoneNumber}
                      </a>
                    </Typography>
                  </Grid>
                  <Grid item sm={2} xs={12} className={classes.mobileCenter}>
                    <Typography variant='body1'>
                      <b>{t('clinicBlocks.email')}</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={10} xs={12} className={classes.mobileCenter}>
                    <Typography variant='body1'>
                      <a href={`mailto:${company[0].email}`} target='_blank' rel='noopener noreferrer'>
                        {company[0].email}
                      </a>
                    </Typography>
                  </Grid>
                  <Grid item sm={2} xs={12} className={classes.mobileCenter}>
                    <Typography variant='body1'>
                      <b>{t('clinicBlocks.website')}</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={10} xs={12} className={classes.mobileCenter}>
                    <Typography variant='body1'>
                      <a href={`${company[0].website}`} target='_blank' rel='noopener noreferrer'>
                        {company[0].website}
                      </a>
                    </Typography>
                  </Grid>
                  <Grid item xs={5}></Grid>
                  <Grid item xs={2} align='center'>
                    <Divider />
                  </Grid>
                  {promoClaimed ? null : (
                    <Grid item xs={12} container spacing={2}>
                      <Grid item xs={12} align='center' className={classes.spacingTop}>
                        <Typography variant='body2'>{t('clinicBlocks.emailSent')}</Typography>

                        <Typography variant='body2'>{t('clinicBlocks.emailSentTwo')}</Typography>
                      </Grid>
                      <Grid item xs={12} align='center'>
                        <ThermageButton variant='contained' color='primary' onClick={this.sendEmailToClinic} className={classes.margin}>
                          {t('clinicBlocks.contact')}
                        </ThermageButton>
                      </Grid>
                      <Grid item xs={12} align='center'>
                        <Button onClick={this.pickAnotherClinic} className={classes.anotherClinic}>
                          {t('clinicBlocks.pickAnother')}
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Snackbar open={promoPopUp} autoHideDuration={6000} onClose={this.closePopUps}>
              <Alert onClose={this.closePopUps} severity='success'>
                {promoMessage}
              </Alert>
            </Snackbar>
            <Snackbar open={promoErrorPopUp} autoHideDuration={6000} onClose={this.closePopUps}>
              <Alert onClose={this.closePopUps} severity='error'>
                {promoError}
              </Alert>
            </Snackbar>
          </div>
        );
      } else {
        return <LoadingBlock />;
      }
    } else {
      return <LoadingBlock />;
    }
  }
}
ClinicBlock.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  //console.log('this is my state', state)
  return {
    auth: state.firebase.auth,
    user: state.firebase.profile,
    companyError: state.company.companyError,
    companyMessage: state.company.companyMessage,
    company: state.firestore.ordered.companies,
    promoError: state.promos.promoError,
    promoErrorPopUp: state.promos.promoErrorPopUp,
    promoMessage: state.promos.promoMessage,
    promoPopUp: state.promos.promoPopUp,
    promoID: state.promos.promoID,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendContactEmail: ({ userID, promoID, clinicID, user, company }) => dispatch(sendContactEmail({ userID, promoID, clinicID, user, company })),
    removeClinicToMyPromo: ({ userID, promoID }) => dispatch(removeClinicToMyPromo({ userID, promoID })),
    closePromosPopUpMess: () => dispatch(closePromosPopUpMess()),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(),
  firestoreConnect((props) => {
    if (props.user.isLoaded) {
      return [{ collection: 'companies', doc: props.user.promos[props.promoID].clinic }];
    } else {
      return [];
    }
  })
)(ClinicBlock);
