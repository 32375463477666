import Geocode from 'react-geocode';
//import geohash from 'ngeohash';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API);

export const getCompanyLogo = (url) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    //const firestore = getFirestore();
    const storage = firebase.storage().ref();

    if (url) {
      storage
        .child(url)
        .getDownloadURL()
        .then((url) => {
          // `url` is the download URL for 'images/stars.jpg'

          // This can be downloaded directly:
          let xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = function (event) {
            //var blob = xhr.response;
          };
          xhr.open('GET', url);
          xhr.send();

          let resp = { url: url };
          dispatch({ type: 'LOGO_WAS_FOUND', resp });
          // Or inserted into an <img> element:
          //let img = document.getElementById('myimg');
          //img.src = url;
        })
        .catch((error) => {
          dispatch({ type: 'LOGO_WAS_NOT_FOUND', error });
        });
    } else {
      dispatch({ type: 'DEFAULT_LOGO' });
    }
  };
};

export const findCompanyFromCompanyCode = ({ code, promo }) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    firebase.analytics().logEvent('search_company', { method: 'company_code', company_id: code });

    firestore
      .collection('companies')
      .where('companyCode', '==', code)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          dispatch({ type: 'NO_COMPANY_FOUND_WITH_THAT_CODE' });
        } else {
          const resp = [];
          snapshot.forEach((doc) => {
            const clinicInfo = doc.data();
            //console.log('this is my clinic data', clinicInfo);
            if (clinicInfo.promos) {
              let inPromoProgram = clinicInfo.promos.includes(promo);
              //console.log('am i in the promo program', inPromoProgram);
              //console.log('this is my clinicInfo', clinicInfo)
              if (inPromoProgram) {
                resp.push({ id: doc.id, data: doc.data() });
                dispatch({ type: 'COMPANY_FOUND_WITH_CODE', resp });
              } else {
                dispatch({ type: 'COMPANY_FOUND_NOT_IN_PROMO_PROGRAM' });
              }
            } else {
              dispatch({ type: 'COMPANY_FOUND_NOT_IN_PROMO_PROGRAM' });
            }
          });
        }
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_LOKING_FOR_COMPANY_WITH_CODE', err });
      });
  };
};

export const findCompanyFromCity = ({ city, promo }) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    firebase.analytics().logEvent('search_company', { method: 'company_city', company_id: city });

    Geocode.fromAddress(`${city}, Canada`)
      .then((response) => {
        const cityLat = response.results[0].geometry.location.lat;
        const cityLong = response.results[0].geometry.location.lng;
        const distance = 63; //miles 50miles 80km   100km = 62.13
        const latDist = 0.0144927536231884; // degrees latitude per mile
        const lonDist = 0.0181818181818182; // degrees longitude per mile

        const lowerLat = cityLat - latDist * distance;
        const lowerLong = cityLong - lonDist * distance;

        const upperLat = cityLat + latDist * distance;
        const upperLong = cityLong + lonDist * distance;

        //const cityGeo = geohash.encode(cityLat, cityLong);
        //const lower = geohash.encode(lowerLat, lowerLong);
        //const upper = geohash.encode(upperLat, upperLong);

        firestore
          .collection('companies')
          .where('promos', 'array-contains', promo)
          .get()
          .then((snapshot) => {
            if (snapshot.empty) {
              dispatch({ type: 'NO_COMPANY_FOUND_WITH_RUNNING_THAT_PROMO_CODE' });
            } else {
              const resp = [];
              snapshot.forEach((doc) => {
                const clinicInfo = doc.data();
                if (clinicInfo.location.lat >= lowerLat && clinicInfo.location.lat <= upperLat) {
                  //console.log('this is my clinicInfo', clinicInfo);
                  //console.log('I get added because I am in the lat');
                  if (clinicInfo.location.long >= lowerLong && clinicInfo.location.long <= upperLong) {
                    //console.log('I get added because I am in the long');
                    resp.push({ id: doc.id, data: doc.data() });
                  } else {
                    //dispatch({ type: 'COMPANY_TO_FAR_AWAY_FROM_LOCATION' });
                  }
                } else {
                  //dispatch({ type: 'COMPANY_TO_FAR_AWAY_FROM_LOCATION' });
                }
              });

              //consol;

              if (resp.length > 0) {
                dispatch({ type: 'COMPANY_FOUND_IN_LOCATION', resp });
              } else {
                dispatch({ type: 'COMPANY_FOUND_NOT_IN_PROMO_PROGRAM' });
              }
            }
          });
      })
      .catch((err) => {
        //console.log('there was an error searching for the city', err);
        dispatch({ type: 'ERROR_SEARCHING_YOUR_LOCATION', err });
      });
  };
};

export const clearCompanySearch = () => {
  return (dispatch, getState) => {
    //console.log('this is the id i am trying to set', promoID)
    dispatch({ type: 'CLEAR_COMPANY_SEARCH' });
  };
};

export const closeCompanyPopUps = () => {
  return (dispatch, getState) => {
    //console.log('this is the id i am trying to set', promoID)
    dispatch({ type: 'CLOSE_COMPANY_POPUPS' });
  };
};
