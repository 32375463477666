const initState = {
  authError: null,
  authMessage: null,
  authPopUp: false,
  authErrorPopUp: false,
  userChecked: false,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case 'LOGIN_ERROR':
      return {
        ...state,
        authError: action.err.message,
        authErrorPopUp: true,
      };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        authError: null,
      };
    case 'SIGNOUT_SUCCESS':
      //console.log('signout success');
      return state;
    case 'NEW_USER_CREATED':
      //console.log('New User has been created');
      return {
        ...state,
        authError: null,
      };
    case 'NEW_USER_ERROR':
      //console.log('User was not created');
      return {
        ...state,
        authError: action.err.message,
        authErrorPopUp: true,
      };
    case 'USER_DB_INFO_UPDATED':
      //console.log('Users information has been updated');
      return {
        ...state,
        authError: null,
      };
    case 'USER_DB_INFO_ERROR':
      //console.log('Users information has not been udpated');
      return {
        ...state,
        authError: action.err.message,
      };
    case 'USER_EMAIL_HAS_BEEN_UPDATED':
      //console.log('Users login email has been updated in the database and online');
      return {
        ...state,
        authMessage: 'Your email has been updated',
        authPopUp: true,
      };
    case 'USER_EMAIL_NOT_UPDATED':
      //console.log('Users email has NOT been updated in the database');
      return {
        ...state,
        authError: action.err.message,
      };
    case 'USER_ID_EMAIL_NOT_UPDATED':
      //console.log('Users Login email has NOT been updated');
      return {
        ...state,
        authError: action.err.message,
      };
    case 'CHANGE_EMAIL_REAUTHENTICATION_ERROR':
      //console.log('User Failed to login while attempting to change their login Email');
      return {
        ...state,
        authError: action.err.message,
      };
    case 'USER_PASSWORD_WAS_NOT_UPDATED':
      //console.log('Users Password was not updated');
      return {
        ...state,
        authError: action.err.message,
        authErrorPopUp: true,
      };
    case 'CHANGE_PASSWORD_REAUTHENTICATION_ERROR':
      //console.log('Original Password was not correct');
      return {
        ...state,
        authError: action.err.message,
        authErrorPopUp: true,
      };
    case 'USER_PASSWORD_WAS_UPDATED':
      //console.log('Users password has been updated!');
      return {
        ...state,
        authMessage: 'Your password has beend updated.',
        authPopUp: true,
      };
    case 'PASSWORD_RESET_SENT':
      return {
        ...state,
        authMessage: 'An email has been sent to reset your password',
        authPopUp: true,
      };
    case 'ERROR_SENDING_PASSWORD_RESET':
      return {
        ...state,
        authError: 'An email reset failed to be sent',
        authErrorPopUp: true,
      };
    case 'CLOSE_AUTH_POPUPS':
      return {
        ...state,
        authError: null,
        authErrorPopUp: false,
        authMessage: null,
        authPopUp: false,
      };
    case 'USER_IS_CLINIC':
      return {
        ...state,
        userChecked: true,
      };
    default:
      return state;
  }
};

export default authReducer;
