import { firestoreConnect } from 'react-redux-firebase';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { addClinicToMyPromo } from '../../actions/promoActions';
import { clearCompanySearch } from '../../actions/companyActions';
import { ThermageButton } from '../Buttons/thermageButton';
import LoadingBlock from '../Loading';
import Button from '@material-ui/core/Button';

const styles = {
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 2,
    textAlign: 'left',
    color: '#000',
  },

  alignCenter: {
    textAlign: 'center',
  },
  mobileCenter: {
    '@media (max-width: 599px)': {
      textAlign: 'center',
    },
  },
  orSpacing: {
    marginTop: 30,
  },
  purple: {
    color: '#9b3e96',
  },
  spacingTop: {
    marginTop: 30,
  },
};

const INITIAL_STATE = {
  //pagePromo: 'olff8ViVEXKmuBK179Zs' //This is the id of the promo, this will change to load from the url once we have more then one promo
};

class ClinicCodeBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
  }

  componentWillUnmount() {}

  linkClinicToMyPromo = (companyID, company) => {
    //console.log('this is my company data to send', company);
    this.props.addClinicToMyPromo({ userID: this.props.auth.uid, promoID: this.props.promoID, clinicID: companyID, user: this.props.user, company: company });
  };

  clearSearch = () => {
    this.props.clearCompanySearch();
  };

  render() {
    //console.log('this is company details', company)

    const { t, classes, company } = this.props;
    //console.log('these are my clinics', company);
    //console.log('this is my company', company);
    //console.log('this is my user', this.props.user);

    if (company) {
      return (
        <div>
          <Grid container spacing={3} justify='center' alignItems='center'>
            <Grid item sm={4} xs={2}>
              <Divider />
            </Grid>
            <Grid item sm={4} xs={8} align='center'>
              <Typography variant='h6'>{t('clinicBlocks.clinicsFound', { number: company.length ? company.length : 1 })}</Typography>
            </Grid>
            <Grid item sm={4} xs={2}>
              <Divider />
            </Grid>
          </Grid>
          <ClinicBlock companies={company} classes={classes} t={t} linkClinicToMyPromo={this.linkClinicToMyPromo} />

          <Grid item xs={12} className={classes.orSpacing}>
            <Grid item container spacing={3} justify='center' alignItems='center'>
              <Grid item xs={2}>
                <Divider />
              </Grid>
              <Grid item xs={4} align='center'>
                {t('signIn.or')}
              </Grid>
              <Grid item xs={2}>
                <Divider />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} align='center' className={classes.orSpacing}>
            <Button onClick={this.clearSearch}>{t('clinicBlocks.searchForAnother')}</Button>
          </Grid>
        </div>
      );
    } else {
      return <LoadingBlock />;
    }
  }
}

const ClinicBlock = ({ companies, classes, t, linkClinicToMyPromo }) => {
  //console.log('this is my company length', companies.length);

  if (companies.length) {
    let block = companies.map((c) => {
      //console.log('this is my c', c);
      return (
        <Grid container spacing={3} justify='center' key={c.id}>
          <Grid item xs={12} align='center' className={classes.spacingTop}>
            <Typography variant='h4' className={classes.purple}>
              {c.data.name}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container item spacing={2}>
              <Grid item sm={2} xs={12} className={classes.mobileCenter}>
                <Typography variant='body1'>
                  <b>{t('clinicBlocks.location')}</b>
                </Typography>
              </Grid>
              <Grid item sm={10} xs={12} className={classes.mobileCenter}>
                <Typography variant='body1'>
                  {c.data.location.streetAddress}
                  {c.data.location.streetAddress2}
                </Typography>
              </Grid>
              <Grid item sm={2} xs={12} className={classes.mobileCenter}>
                <Typography variant='body1'>
                  <b>{t('clinicBlocks.city')}</b>
                </Typography>
              </Grid>
              <Grid item sm={10} xs={12} className={classes.mobileCenter}>
                <Typography variant='body1'>{c.data.location.city}</Typography>
              </Grid>
              <Grid item sm={2} xs={12} className={classes.mobileCenter}>
                <Typography variant='body1'>
                  <b>{t('clinicBlocks.phone')}</b>
                </Typography>
              </Grid>
              <Grid item sm={10} xs={12} className={classes.mobileCenter}>
                <Typography variant='body1'>
                  <a href={`tel://${c.data.phoneNumber}`} target='_blank' rel='noopener noreferrer'>
                    {c.data.phoneNumber}
                  </a>
                </Typography>
              </Grid>
              <Grid item sm={2} xs={12} className={classes.mobileCenter}>
                <Typography variant='body1'>
                  <b>{t('clinicBlocks.email')}</b>
                </Typography>
              </Grid>
              <Grid item sm={10} xs={12} className={classes.mobileCenter}>
                <Typography variant='body1'>
                  <a href={`mailto:${c.data.email}`} target='_blank' rel='noopener noreferrer'>
                    {c.data.email}
                  </a>
                </Typography>
              </Grid>
              <Grid item sm={2} xs={12} className={classes.mobileCenter}>
                <Typography variant='body1'>
                  <b>{t('clinicBlocks.website')}</b>
                </Typography>
              </Grid>
              <Grid item sm={10} xs={12} className={classes.mobileCenter}>
                <Typography variant='body1'>
                  <a href={`${c.data.website}`} target='_blank' rel='noopener noreferrer'>
                    {c.data.website}
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={5}></Grid>
              <Grid item xs={2} align='center' className={classes.spacingTop}>
                <Divider />
              </Grid>
              <Grid item xs={12} align='center'>
                <ThermageButton variant='contained' color='primary' onClick={(e) => linkClinicToMyPromo(c.id, c)} className={classes.margin}>
                  {t('clinicBlocks.select')}
                </ThermageButton>
              </Grid>
              <Grid item xs={12} className={classes.spacingTop}>
                <Divider />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    });

    //console.log('this is my block', block);
    return block;
  } else {
    return (
      <Grid container spacing={3} justify='center' key={companies.id}>
        <Grid item xs={12} align='center'>
          <Typography variant='h4' className={classes.purple}>
            {companies.data.name}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container item spacing={2}>
            <Grid item sm={2} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                <b>{t('clinicBlocks.location')}</b>
              </Typography>
            </Grid>
            <Grid item sm={10} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                {companies.data.location.streetAddress}
                {companies.data.location.streetAddress2}
              </Typography>
            </Grid>
            <Grid item sm={2} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                <b>{t('clinicBlocks.city')}</b>
              </Typography>
            </Grid>
            <Grid item sm={10} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>{companies.data.location.city}</Typography>
            </Grid>
            <Grid item sm={2} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                <b>{t('clinicBlocks.phone')}</b>
              </Typography>
            </Grid>
            <Grid item sm={10} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                <a href={`tel://${companies.data.phoneNumber}`} target='_blank' rel='noopener noreferrer'>
                  {companies.data.phoneNumber}
                </a>
              </Typography>
            </Grid>
            <Grid item sm={2} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                <b>{t('clinicBlocks.email')}</b>
              </Typography>
            </Grid>
            <Grid item sm={10} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                <a href={`mailto:${companies.data.email}`} target='_blank' rel='noopener noreferrer'>
                  {companies.data.email}
                </a>
              </Typography>
            </Grid>
            <Grid item sm={2} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                <b>{t('clinicBlocks.website')}</b>
              </Typography>
            </Grid>
            <Grid item sm={10} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                <a href={`${companies.data.website}`} target='_blank' rel='noopener noreferrer'>
                  {companies.data.website}
                </a>
              </Typography>
            </Grid>
            <Grid item xs={5}></Grid>
            <Grid item xs={2} align='center'>
              <Divider />
            </Grid>
            <Grid item xs={12} align='center'>
              <ThermageButton variant='contained' color='primary' onClick={(e) => linkClinicToMyPromo(companies.id, companies)} className={classes.margin}>
                {t('clinicBlocks.select')}
              </ThermageButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
};

ClinicCodeBlock.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  //console.log('this is my state', state);
  return {
    auth: state.firebase.auth,
    user: state.firebase.profile,
    companyError: state.company.companyError,
    companyMessage: state.company.companyMessage,
    company: state.company.company,
    promoID: state.promos.promoID,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addClinicToMyPromo: ({ userID, promoID, clinicID, user, company }) => dispatch(addClinicToMyPromo({ userID, promoID, clinicID, user, company })),
    clearCompanySearch: () => dispatch(clearCompanySearch()),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(),
  firestoreConnect((props) => [
    //{ collection: 'consumer', doc: props.match.params.id }
  ])
)(ClinicCodeBlock);
