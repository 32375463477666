import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import ThermageLogo from '../../images/thermage_eyes.svg';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Paper from '@material-ui/core/Paper';
import headerImage from '../../images/1x/header-image-desktop.jpg';
import headerTabletImage from '../../images/1x/header-image-tablet.jpg';
import headerMobileImage from '../../images/1x/header-image-mobile.jpg';
import SoltaLogo from '../../images/solta_logo.svg';
import Hidden from '@material-ui/core/Hidden';

const styles = {
  root: {
    flexGrow: 1,
    height: '100%',
  },
  paper: {
    padding: 2,
    textAlign: 'left',
    color: '#000',
  },
  logo: {
    marginTop: 30,
    width: 155,
    '@media (max-width: 959px)': {
      //width: 125,
      marginTop: 50,
    },
    '@media (max-width: 599px)': {
      width: 125,
      marginTop: 0,
    },
  },

  purpleLine: {
    background: 'linear-gradient(90deg, rgba(67,22,63,1) 0%, rgba(155,62,150,1) 100%)',
    display: 'block',
    height: 10,
  },
  headerImage: {
    display: 'block',
    margin: 0,
    padding: 0,
  },
  headerBlock: {
    backgroundImage: `url(${headerImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#EDEDED',
    height: 250,
    display: 'block',
    backgroundSize: '75%',
    backgroundPosition: 'center left',
  },
  headerTabletBlock: {
    backgroundImage: `url(${headerTabletImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#EDEDED',
    height: 250,
    display: 'block',
    backgroundSize: '75%',
    backgroundPosition: 'center left',
  },
  headerMobileBlock: {
    backgroundImage: `url(${headerMobileImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#EDEDED',
    height: 215,
    display: 'block',
  },
  header: {
    height: 250,
  },
  soltaLogo: {
    width: 250,
    marginRight: 50,
    marginTop: 50,
    '@media (max-width: 1919px)': {
      marginRight: 0,
    },
    '@media (max-width: 1025px)': {
      width: 175,
    },
    '@media (max-width: 959px)': {
      width: 150,
      marginTop: 80,
    },
  },
  purpleGradient: {
    //background: 'rgb(255,255,255)',
    background: 'linear-gradient(0deg, rgba(255,255,255,0.45) 0%, rgba(204,156,201,0.45) 100%)',
    height: 60,
    display: 'block',
  },
  logoBlock: {
    position: 'relative',
    display: 'block',
    '@media (max-width: 1919px)': {
      marginTop: 30,
    },
    '@media (max-width: 599px)': {
      marginTop: 0,
    },
  },
  questionMark: {
    right: -50,
    display: 'block',
    position: 'absolute',
    color: ' rgba(255,255,255,0.45) 0% !important',
    top: 25,
    '@media (max-width: 1919px)': {
      right: '25%',
    },
    '@media (max-width: 1300px)': {
      right: '25%',
    },
    '@media (max-width: 959px)': {
      right: '10%',
      top: 40,
    },
    '@media (max-width: 599px)': {
      right: '25%',
      top: 0,
    },
    purple: {
      color: 'rgba(67,22,63,1) !important',
    },
  },
};

class ImageHeader extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Hidden lgDown>
            <Grid item xl={12} lg={12} xs={12} className={classes.headerBlock}>
              <Grid item container xl={12} xs={12} className={classes.header}>
                <Grid item xl={4} lg={4} md={4} xs={4}></Grid>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                  <Grid container item xs={12} lg={12} xl={12} spacing={1} className={classes.header} justify='center' alignItems='center'>
                    <Grid item xs={3} align='right'>
                      <div className={classes.logoBlock}>
                        <Link to={ROUTES.ABOUT} target='_blank' className={classes.purple}>
                          <img src={ThermageLogo} alt='Solta' className={classes.logo} />
                          <HelpOutlineIcon className={classes.questionMark} />
                        </Link>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xl={4} lg={4} md={4} xs={4} align='right' container alignItems='flex-start'>
                  <Grid item xs={12}>
                    <img src={SoltaLogo} alt='Solta' className={classes.soltaLogo} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={12} xs={12}>
              <Paper square elevation={0} className={classes.purpleLine}></Paper>
            </Grid>
            <Grid item lg={12} xs={12}>
              <Paper square elevation={0} className={classes.purpleGradient}></Paper>
            </Grid>
          </Hidden>

          <Hidden xlUp smDown>
            <Grid item xl={12} lg={12} xs={12} className={classes.headerTabletBlock}>
              <Grid item container xl={12} xs={12} className={classes.header}>
                <Grid item sm={8} xs={10}></Grid>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={2}>
                  <Grid item container alignItems='center'>
                    <Grid item xs={12} align='center'>
                      <div className={classes.logoBlock}>
                        <Link to={ROUTES.ABOUT} target='_blank' className={classes.purple}>
                          <img src={ThermageLogo} alt='Solta' className={classes.logo} />
                          <HelpOutlineIcon className={classes.questionMark} />
                        </Link>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={12} xs={12}>
              <Paper square elevation={0} className={classes.purpleLine}></Paper>
            </Grid>
            <Grid item lg={12} xs={12}>
              <Paper square elevation={0} className={classes.purpleGradient}></Paper>
            </Grid>
          </Hidden>

          <Hidden mdUp xsDown>
            <Grid item xs={12} className={classes.headerMobileBlock}>
              <Grid item container xs={12}>
                <Grid item sm={7} xs={7}></Grid>
                <Grid item sm={4} xs={4}>
                  <Grid item container alignItems='center'>
                    <Grid item xs={12} align='center'>
                      <div className={classes.logoBlock}>
                        <Link to={ROUTES.ABOUT} target='_blank' className={classes.purple}>
                          <img src={ThermageLogo} alt='Solta' className={classes.logo} />
                          <HelpOutlineIcon className={classes.questionMark} />
                        </Link>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Paper square elevation={0} className={classes.purpleLine}></Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper square elevation={0} className={classes.purpleGradient}></Paper>
            </Grid>
          </Hidden>

          <Hidden smUp>
            <Grid item xs={12} className={classes.headerMobileBlock}>
              <Grid item container xs={12}>
                <Grid item sm={7} xs={7}></Grid>
                <Grid item sm={4} xs={4}>
                  <Grid item container alignItems='center'></Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Paper square elevation={0} className={classes.purpleLine}></Paper>
            </Grid>
            <Grid item xs={12} align='center'>
              <Paper square elevation={0} className={classes.purpleGradient}></Paper>
              <div className={classes.logoBlock}>
                <Link to={ROUTES.ABOUT} target='_blank' className={classes.purple}>
                  <img src={ThermageLogo} alt='Solta' className={classes.logo} />
                  <HelpOutlineIcon className={classes.questionMark} />
                </Link>
              </div>
            </Grid>
          </Hidden>
        </Grid>
      </div>
    );
  }
}

ImageHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    //auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //createEmailUser: (newUser) => dispatch(createEmailUser(newUser)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles), withTranslation())(ImageHeader);
