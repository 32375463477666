//import moment from 'moment';

export const setPromo = (promoID) => {
  return (dispatch, getState) => {
    //console.log('this is the id i am trying to set', promoID)
    dispatch({ type: 'SET_PROMO_CODE', promoID });
  };
};

export const addClinicToMyPromo = ({ userID, promoID, clinicID, user, company }) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    firebase.analytics().logEvent('select_company', { name: 'add', company_id: clinicID, user: userID });
    //console.log('this is my end date', moment(newPromo.promoEndDate).valueOf())

    const promoUpdateLine = 'promos.' + [promoID] + '.clinic';

    firestore
      .collection('consumer')
      .doc(userID)
      .update({
        [promoUpdateLine]: clinicID,
      })
      .then(() => {
        let companyName = company.data ? company.data.name : company.name;
        let companyEmail = company.data ? company.data.email : company.email;

        firestore
          .collection('mail')
          .add({
            cc: [user.email, companyEmail],
            message: {
              subject: 'You have linked your promo with a clinic',
              text: `Congratulations ${user.firstName}, you have linked your promo with the following clininc: ${companyName}. You can claim your promo by taking your promo code: ${user.promos[promoID].code} to the clinic. We have included the clinic in this email to help you book an appointment. `,
              html: `Congratulations ${user.firstName},<br/> You have linked your promo with the following clininc:<b> ${companyName}</b>. <br/> You can claim your promo by taking your promo code:<b> ${user.promos[promoID].promoCode}</b> to the clinic.<br/> We have included the clinic in this email to help you book an appointment.<br/> Thank you for signing up with The Solta Advantage Program. `,
            },
          })
          .then(() => {
            dispatch({ type: 'ADDED_CLINIC_TO_CONSUMER' });
          })
          .catch((err) => {
            dispatch({ type: 'ERROR_SENDING_CLINIC_EMAIL', err });
          });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_ADDING_CLINIC_TO_CONSUMER', err });
      });
  };
};

export const sendContactEmail = ({ userID, promoID, clinicID, user, company }) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    firebase.analytics().logEvent('contact_email_sent', { company_id: clinicID, user: userID });
    //console.log('this is my end date', moment(newPromo.promoEndDate).valueOf())

    firestore
      .collection('mail')
      .add({
        cc: [user.email, company.email],
        message: {
          subject: `Book an Appointment for ${user.fullName}`,
          text: `Hi ${company.name}, I would like to book an appointment so that I could see if Thermage is right for me. Thanks ${user.firstName} `,
          html: `Hi ${company.name}, I would like to book an appointment so that I could see if Thermage is right for me. Thanks ${user.firstName} `,
        },
      })
      .then(() => {
        dispatch({ type: 'CONTACT_EMAIL_SENT' });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_SENDING_CONTACT_EMAIL', err });
      });
  };
};

export const removeClinicToMyPromo = ({ userID, promoID }) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firebase.analytics().logEvent('select_company', { name: 'remove', user: userID });
    //console.log('this is my end date', moment(newPromo.promoEndDate).valueOf())
    const promoUpdateLine = 'promos.' + [promoID] + '.clinic';
    firestore
      .collection('consumer')
      .doc(userID)
      .update({
        [promoUpdateLine]: '',
      })
      .then(() => {
        dispatch({ type: 'REMOVED_CLINIC_FROM_CONSUMER' });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_REMOVING_CLINIC_FROM_CONSUMER', err });
      });
  };
};

export const closePromosPopUpMess = () => {
  return (dispatch) => {
    dispatch({ type: 'CLOSE_PROMO_POPUPS' });
  };
};
