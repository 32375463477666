export const LANDING = '/';
export const LOGIN = '/login';
export const LOGIN_REDIRECT = '/login/:id';
export const HOME = '/';
export const SIGN_UP = '/signup';
export const SIGN_UP_REDIRECT = '/signup/:id';
export const PASSWORD_FORGET = '/pw-forget';
export const SEND = '/send';
export const FAQ = '/faq';
export const TERMS = '/terms';
export const ABOUT = '/about';
export const CLINICS = '/p/:id';
export const CLINIC = '/p/';
export const FORGOTTEN_PASSWORD = '/password-reset';
export const PROFILE = '/profile';
