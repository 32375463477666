import { Redirect, Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { firestoreConnect } from 'react-redux-firebase';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ThermageLogo from '../../images/thermage_eyes.svg';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Divider from '@material-ui/core/Divider';
import Footer from '../Footer';
import LogoHeader from '../Header';
import { updateUserPassword, closeAuthPopUps } from '../../actions/authActions';
import LoadingBlock from '../Loading';
import TextField from '@material-ui/core/TextField';
//import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { ThermageButton } from '../Buttons/thermageButton';

const styles = {
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 2,
    textAlign: 'left',
    color: '#000',
  },
  customerName: {
    fontFamily: 'Montserrat',
    fontWeight: 300,
    color: '#9b3e96',
    fontSize: 48,
    '@media (max-width: 1025px)': {
      fontSize: 28,
    },
    '@media (max-width: 599px)': {
      textAlign: 'center',
    },
  },
  logo: {
    marginTop: 30,
    width: 155,
    display: 'block',
    '@media (max-width: 1025px)': {
      width: 135,
      marginLeft: -40,
      marginTop: 0,
    },
  },
  alignCenter: {
    textAlign: 'center',
  },
  questionMark: {
    right: -50,
    display: 'block',
    position: 'absolute',
    top: 0,
    '@media (max-width: 1440px)': {
      right: -70,
    },
    '@media (max-width: 1300px)': {
      right: -85,
    },
    '@media (max-width: 1025px)': {
      right: -30,
    },
    '@media (max-width: 850px)': {
      right: -50,
    },
    '@media (max-width: 599px)': {
      right: -30,
    },
  },
  logoBlock: {
    position: 'relative',
    display: 'block',
  },
  mobileCenter: {
    '@media (max-width: 599px)': {
      textAlign: 'center',
    },
  },
  textField: {
    width: '100%',
  },
  magrinTopBlock: {
    paddingTop: '30px',
  },
};

const INITIAL_STATE = {
  currentPassword: '',
  newPassword: '',
  checkPassword: '',
  loading: true,
};

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

class ProfilePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate() {
    //this.setState({ loading: true });
    if (this.state.loading) {
      this.setState({
        loading: false,
      });
    }
  }

  changePassword = (event) => {
    event.preventDefault();
    if (this.state.newPassword !== this.state.checkPassword) {
      this.setState({
        passwordError: 'Passwords do not match',
      });
    } else {
      // Need to put in some checks to see if password is strong enough
      console.log('NEED TO PUT IN PASSWORD SECURITY RULES');
      this.props.updateUserPassword({ currentPassword: this.state.currentPassword, email: this.props.user.email, newPassword: this.state.newPassword });

      this.setState({
        currentPassword: '',
        newPassword: '',
        checkPassword: '',
        passwordOpen: false,
      });
    }
  };

  closePopUps = () => {
    this.props.closeAuthPopUps();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { currentPassword, newPassword, checkPassword } = this.state;

    const { auth, t, classes, user, authError, authMessage, authErrorPopUp, authPopUp } = this.props;
    if (!auth.uid) return <Redirect to={ROUTES.LOGIN} />;
    //console.log('this is my user', user);

    if (user.isLoaded && !user.isEmpty) {
      return (
        <div className='meta-react'>
          <LogoHeader t={t} />
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className={classes.root}>
                  <Grid container spacing={3} justify='center'>
                    <Grid item xs={12} sm={6}>
                      <Typography variant='h3' className={classes.customerName}>
                        {user.fullName}
                      </Typography>
                      <br />
                      <Typography variant='h5' className={classes.mobileCenter}>
                        {t('profile.title')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid container spacing={1} alignItems='center' justify='center'>
                        <Grid item xs={3}>
                          <div className={classes.logoBlock}>
                            <Link to={ROUTES.ABOUT} target='_blank' className={classes.purple}>
                              <img src={ThermageLogo} alt='Solta' className={classes.logo} />
                              <HelpOutlineIcon className={classes.questionMark} />
                            </Link>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item container spacing={3} xs={12} alignItems='center' justify='center'>
                      <Grid item sm={4} xs={12} container spacing={4} alignItems='center' justify='center'>
                        <form onSubmit={this.changePassword}>
                          <Grid item xs={12}>
                            <Typography variant='h6'>{t('profile.changePassTitle')}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField id='standard-password-input' label={t('profile.currentPassword')} type='password' autoComplete='current-password' name='currentPassword' value={currentPassword} onChange={this.onChange} className={classes.textField} />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField id='newPassword' name='newPassword' label={t('profile.newPassword')} type='password' value={newPassword} onChange={this.onChange} className={classes.textField} />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField id='checkPassword' name='checkPassword' label={t('profile.retypePassword')} type='password' value={checkPassword} onChange={this.onChange} className={classes.textField} />
                          </Grid>
                          <Grid item xs={12} align='center' className={classes.magrinTopBlock}>
                            <ThermageButton variant='contained' color='primary' onClick={this.changePassword}>
                              {t('profile.updateButton')}
                            </ThermageButton>
                          </Grid>
                        </form>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Snackbar open={authPopUp} autoHideDuration={6000} onClose={this.closePopUps}>
                    <Alert onClose={this.closePopUps} severity='success'>
                      {authMessage}
                    </Alert>
                  </Snackbar>
                  <Snackbar open={authErrorPopUp} autoHideDuration={6000} onClose={this.closePopUps}>
                    <Alert onClose={this.closePopUps} severity='error'>
                      {authError}
                    </Alert>
                  </Snackbar>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      );
    } else {
      return (
        <div className='meta-react'>
          <LogoHeader t={t} />
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className={classes.root}>
                  <Grid container spacing={3} justify='center'>
                    <Grid item xs={12} align='center'>
                      <LoadingBlock />
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      );
    }
  }
}

ProfilePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  //console.log('this is my state', state);
  return {
    auth: state.firebase.auth,
    user: state.firebase.profile,
    authError: state.auth.authError,
    authMessage: state.auth.authMessage,
    authPopUp: state.auth.authPopUp,
    authErrorPopUp: state.auth.authErrorPopUp,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserPassword: (user) => dispatch(updateUserPassword(user)),
    closeAuthPopUps: () => dispatch(closeAuthPopUps()),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(),
  firestoreConnect((props) => [
    //{ collection: 'promos' }
  ])
)(ProfilePage);
