import { firestoreConnect } from 'react-redux-firebase';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
//import ThermageLogo from '../../images/thermage_eyes.svg';
//import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
//import Divider from '@material-ui/core/Divider';
import Footer from '../Footer';
import ImageHeader from '../Header/imageHeader';
import ReactPlayer from 'react-player/lazy';
import ThermageEyeVideoM4V from '../../videos/thermage_eyes_focus.m4v';
import ThermageEyeVideoMOV from '../../videos/thermage_eyes_focus.mov';
import ThermageEyeVideoWEBM from '../../videos/thermage_eyes_focus.webm';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
//import ThermageEyeImageBeforeA from '../../images/eye_images/before_a.jpg';
//import ThermageEyeImageBeforeB from '../../images/eye_images/before_b.jpg';
//import ThermageEyeImageBeforeC from '../../images/eye_images/before_c.jpg';
//import ThermageEyeImageBeforeD from '../../images/eye_images/before_d.jpg';
//import ThermageEyeImageAfterA from '../../images/eye_images/after_a.jpg';
//import ThermageEyeImageAfterB from '../../images/eye_images/after_b.jpg';
//import ThermageEyeImageAfterC from '../../images/eye_images/after_c.jpg';
//import ThermageEyeImageAfterD from '../../images/eye_images/after_d.jpg';
import ThermageEyeGroupA from '../../images/eye_images/before_n_after_a.jpg';
import ThermageEyeGroupB from '../../images/eye_images/before_n_after_b.jpg';
import ThermageEyeGroupC from '../../images/eye_images/before_n_after_c.jpg';
import ThermageEyeGroupD from '../../images/eye_images/before_n_after_d.jpg';

const styles = {
  root: {
    flexGrow: 1,
    whiteSpace: 'pre-line',
  },
  paper: {
    padding: 2,
    textAlign: 'left',
    color: '#000',
  },
  customerName: {
    fontFamily: 'Montserrat',
    fontWeight: 300,
    color: '#9b3e96',
    fontSize: 48,
    '@media (max-width: 1025px)': {
      fontSize: 28,
    },
    '@media (max-width: 599px)': {
      //textAlign: 'center',
    },
  },
  logo: {
    marginTop: 30,
    width: 155,
    display: 'block',
    '@media (max-width: 1025px)': {
      width: 135,
      marginLeft: -40,
      marginTop: 0,
    },
  },
  alignCenter: {
    textAlign: 'center',
  },
  questionMark: {
    right: -50,
    display: 'block',
    position: 'absolute',
    top: 0,
    '@media (max-width: 1440px)': {
      right: -70,
    },
    '@media (max-width: 1300px)': {
      right: -85,
    },
    '@media (max-width: 1025px)': {
      right: -30,
    },
    '@media (max-width: 850px)': {
      right: -50,
    },
    '@media (max-width: 599px)': {
      right: -30,
    },
  },
  logoBlock: {
    position: 'relative',
    display: 'block',
  },
  mobileCenter: {
    '@media (max-width: 599px)': {
      textAlign: 'center',
    },
  },
  fineprint: {
    fontSize: 11,
  },
  images: {
    width: '100%',
  },
  purple: {
    color: '#9b3e96',
  },
  points: {
    marginBottom: 10,
  },
};

const INITIAL_STATE = {
  loading: true,
};

class ThermageEyePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate() {}

  render() {
    const { t, classes } = this.props;

    return (
      <div className='meta-react'>
        <ImageHeader t={t} />
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className={classes.root}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant='h5' className={classes.customerName}>
                      {t('about.title')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h6'>{t('about.subHeader')}</Typography>
                    <Typography variant='body1'>{t('about.seeDiffernce')}</Typography>
                  </Grid>
                  <Grid item xs={12} align='center'>
                    <ReactPlayer
                      width='100%'
                      height='auto'
                      //playing
                      controls
                      url={[
                        { src: ThermageEyeVideoWEBM, type: 'video/webm' },
                        { src: ThermageEyeVideoM4V, type: 'video/m4v' },
                        { src: ThermageEyeVideoMOV, type: 'video/mov' },
                      ]}
                    />
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <Typography variant='h6' className={`${classes.purple} ${classes.points}`}>
                      {t('about.pointHeaderOne')}
                    </Typography>
                    <Typography variant='body1' className={classes.points}>
                      {t('about.pointOne')}
                    </Typography>
                    <Typography variant='body1' className={classes.points}>
                      {t('about.pointTwo')}
                    </Typography>
                    <Typography variant='body1' className={classes.points}>
                      {t('about.pointThree')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h6' className={`${classes.purple}`}>
                      {t('about.beforeNAfter')}
                    </Typography>
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <SimpleReactLightbox>
                      <SRLWrapper>
                        <Grid item container spacing={2} xs={12}>
                          <Grid item sm={3} xs={6}>
                            <img src={ThermageEyeGroupA} className={classes.images} alt={t('about.imageCap')} />
                          </Grid>
                          <Grid item sm={3} xs={6}>
                            <img src={ThermageEyeGroupB} className={classes.images} alt='Thermage After Eye' />
                          </Grid>
                          <Grid item sm={3} xs={6}>
                            <img src={ThermageEyeGroupC} className={classes.images} alt='Thermage Before Eye' />
                          </Grid>
                          <Grid item sm={3} xs={6}>
                            <img src={ThermageEyeGroupD} className={classes.images} alt='Thermage After Eye' />
                          </Grid>
                        </Grid>
                      </SRLWrapper>
                    </SimpleReactLightbox>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body2' className={classes.fineprint}>
                      {t('about.finePrint')}
                    </Typography>
                    <br />
                    <Typography variant='body2' className={classes.fineprint}>
                      {t('indication.thermage.title')}
                    </Typography>
                    <Typography variant='body2' className={classes.fineprint}>
                      {t('indication.thermage.copy')}
                    </Typography>
                    <Typography variant='body2' className={classes.fineprint}>
                      {t('indication.thermage.pointOne')}
                    </Typography>
                    <Typography variant='body2' className={classes.fineprint}>
                      {t('indication.thermage.pointTwo')}
                    </Typography>
                    <Typography variant='body2' className={classes.fineprint}>
                      {t('indication.thermage.copyTwo')}
                    </Typography>
                    <Typography variant='body2' className={classes.fineprint}>
                      {t('indication.thermage.pointThree')}
                    </Typography>
                    <Typography variant='body2' className={classes.fineprint}>
                      {t('indication.thermage.pointFour')}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

ThermageEyePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  //console.log('this is my state', state);
  return {
    //auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //setPromo: (promoID) => dispatch(setPromo(promoID)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(),
  firestoreConnect((props) => [])
)(ThermageEyePage);
