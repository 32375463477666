import { Redirect, Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { firestoreConnect } from 'react-redux-firebase';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { addClinicToMyPromo, setPromo } from '../../actions/promoActions';
import Typography from '@material-ui/core/Typography';
import ThermageLogo from '../../images/thermage_eyes.svg';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
//import Divider from '@material-ui/core/Divider';
import Footer from '../Footer';
import LogoHeader from '../Header';
//import CircularProgress from '@material-ui/core/CircularProgress';
import LoadingBlock from '../Loading';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
//import { ThermageButton } from '../Buttons/thermageButton';
import { ThermageButton } from '../Buttons/thermageButton';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { closeCompanyPopUps } from '../../actions/companyActions';
import { registerLoggedinUsers } from '../../actions/authActions';
import { getCompanyLogo } from '../../actions/companyActions';
import defaultClinic from '../../images/default_clinic.svg';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = {
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 2,
    textAlign: 'left',
    color: '#000',
  },
  customerName: {
    fontFamily: 'Montserrat',
    fontWeight: 300,
    color: '#9b3e96',
    fontSize: 38,
    '@media (max-width: 1025px)': {
      fontSize: 28,
    },
    '@media (max-width: 599px)': {
      textAlign: 'center',
    },
  },
  logo: {
    marginTop: 30,
    width: 155,
    display: 'block',
    '@media (max-width: 1025px)': {
      width: 135,
      marginLeft: -40,
      marginTop: 0,
    },
  },
  image: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  alignCenter: {
    textAlign: 'center',
  },
  questionMark: {
    right: -50,
    display: 'block',
    position: 'absolute',
    top: 0,
    '@media (max-width: 1440px)': {
      right: -70,
    },
    '@media (max-width: 1300px)': {
      right: -85,
    },
    '@media (max-width: 1025px)': {
      right: -30,
    },
    '@media (max-width: 850px)': {
      right: -50,
    },
    '@media (max-width: 599px)': {
      right: -30,
    },
  },
  logoBlock: {
    position: 'relative',
    display: 'block',
  },
  mobileCenter: {
    textAlign: 'left',
    '@media (max-width: 599px)': {
      textAlign: 'center',
    },
  },
  spacingTop: {
    marginTop: 20,
  },
};

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const INITIAL_STATE = {
  //pagePromo: 'olff8ViVEXKmuBK179Zs', //This is the id of the promo, this will change to load from the url once we have more then one promo,
  loading: true,
  companyEnrolled: false,
};

class ClinicCodeLandingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate() {
    //this.setState({ loading: true });
    if (this.state.loading) {
      if (this.props.promos) {
        if (this.props.promos.length === 1) {
          //console.log('we only have one promo', this.props.promos[0].id)
          this.props.setPromo(this.props.promos[0].id);
          if (this.props.company) {
            let clinicLogoURL = this.props.company[0].logo ? this.props.company[0].logo : '';
            this.props.getCompanyLogo(clinicLogoURL);

            if (this.props.company[0].promos) {
              if (this.props.company[0].promos.includes(this.props.promos[0].id)) {
                this.setState({
                  companyEnrolled: true,
                  loading: false,
                });
              } else {
                this.setState({
                  companyEnrolled: false,
                  loading: false,
                });
              }
            } else {
              this.setState({
                companyEnrolled: false,
                loading: false,
              });
            }
          }
        }
      }
    }
  }

  findUser = () => {
    this.props.registerLoggedinUsers(this.props.auth);
  };

  linkClinicToMyPromo = (companyID, company) => {
    this.props.addClinicToMyPromo({ userID: this.props.auth.uid, promoID: this.props.promoID, clinicID: companyID, user: this.props.user, company: company });
  };

  closePopUps = () => {
    this.props.closeCompanyPopUps();
  };

  render() {
    const {
      //pagePromo
      loading,
      companyEnrolled,
    } = this.state;

    const { auth, t, classes, companyError, companyMessage, user, company, promoID, companyErrorPopUp, companyPopUp, userChecked, companyLogo } = this.props;
    //console.log('this is my props', this.props);
    //console.log('this is my company', company);
    //console.log('is my company enrolled', companyEnrolled);
    //console.log('this is my auth', auth);
    //console.log('this is my user', user);
    if (user.isLoaded && !user.isEmpty) {
      if (user.promos[promoID]) {
        //console.log('I am in this promo');
        if (user.promos[promoID].clinic) {
          return <Redirect to={ROUTES.LANDING} />;
        }
      }
    }

    if (company && !loading) {
      if (user.isLoaded && user.isEmpty && auth.uid) {
        if (!userChecked) {
          this.findUser();
        } else {
          return (
            <div className='meta-react'>
              <LogoHeader t={t} />
              <div className='container'>
                <div className='row'>
                  <div className='col-12'>
                    <div className={classes.root}>
                      <Grid container spacing={3} justify='center'>
                        <Grid item xs={12} sm={6} align='center'>
                          <Typography variant='h6'>{t('home.clinicUserLoggedIn')}</Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          );
        }

        return (
          <div className='meta-react'>
            <LogoHeader t={t} />
            <div className='container'>
              <div className='row'>
                <div className='col-12'>
                  <div className={classes.root}>
                    <Grid container spacing={3} justify='center'>
                      <Grid item xs={12} align='center'>
                        <LoadingBlock />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        );
      } else {
        return (
          <div className='meta-react'>
            <LogoHeader t={t} />
            <div className='container'>
              <div className='row'>
                <div className='col-12'>
                  <div className={classes.root}>
                    <Grid container spacing={3} justify='center' alignItems='center'>
                      <Grid item xs={4} sm={2}>
                        {companyLogo ? <img src={companyLogo === 'default' ? defaultClinic : companyLogo} alt={company[0].name} className={classes.image} /> : <CircularProgress />}
                      </Grid>
                      <Grid item xs={8} sm={4}>
                        <Typography variant='h3' className={classes.customerName}>
                          {company[0].name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Grid container spacing={1} alignItems='center' justify='center'>
                          <Grid item xs={3}>
                            <div className={classes.logoBlock}>
                              <Link to={ROUTES.ABOUT} target='_blank' className={classes.purple}>
                                <img src={ThermageLogo} alt='Solta' className={classes.logo} />
                                <HelpOutlineIcon className={classes.questionMark} />
                              </Link>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        {companyEnrolled ? <CompanyEnrolledBlock t={t} auth={auth} company={company} props={this.props} classes={classes} linkClinicToMyPromo={this.linkClinicToMyPromo} /> : <CompanyNotEnrolledBlock t={t} auth={auth} company={company} />}
                      </Grid>
                    </Grid>
                    <Snackbar open={companyPopUp} autoHideDuration={6000} onClose={this.closePopUps}>
                      <Alert onClose={this.closePopUps} severity='success'>
                        {companyMessage}
                      </Alert>
                    </Snackbar>
                    <Snackbar open={companyErrorPopUp} autoHideDuration={6000} onClose={this.closePopUps}>
                      <Alert onClose={this.closePopUps} severity='error'>
                        {companyError}
                      </Alert>
                    </Snackbar>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        );
      }
    } else {
      return (
        <div className='meta-react'>
          <LogoHeader t={t} />
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className={classes.root}>
                  <Grid container spacing={3} justify='center'>
                    <Grid item xs={12} align='center'>
                      <LoadingBlock />
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      );
    }
  }
}

const CompanyEnrolledBlock = ({ auth, company, t, props, classes, linkClinicToMyPromo }) => {
  if (auth.uid) {
    return (
      <Grid item container spacing={3}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} align='center'>
          <ClinicInfoBlock company={company} t={t} classes={classes} linkClinicToMyPromo={linkClinicToMyPromo} />
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid item container spacing={3}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} align='center'>
          {t('clinic.noAccount')}
        </Grid>
        <Grid item xs={12} align='center'>
          <Button variant='contained' size='large' component={Link} to={ROUTES.SIGN_UP + '/' + props.match.params.id}>
            {t('signIn.register')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Grid item container spacing={2} alignItems='center' justify='center'>
            <Grid item xs={4}>
              <Divider />
            </Grid>
            <Grid item xs={4} align='center'>
              <Typography variant='h6'>{t('signIn.or')}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Divider />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} align='center'>
          <Typography variant='body1'>{t('clinic.pleaseLogin')}</Typography>
        </Grid>
        <Grid item xs={12} align='center'>
          <Button variant='contained' component={Link} to={ROUTES.LOGIN + '/' + props.match.params.id}>
            {t('signIn.signInButton')}
          </Button>
        </Grid>
      </Grid>
    );
  }
};

const CompanyNotEnrolledBlock = ({ auth, company, t }) => {
  return (
    <Grid item container spacing={3}>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} align='center'>
        <Typography variant='body1'>{t('clinic.clinicNotEnrolled')}</Typography>
      </Grid>
    </Grid>
  );
};

const ClinicInfoBlock = ({ company, t, classes, linkClinicToMyPromo }) => {
  return (
    <div className={classes.root}>
      <Grid container spacing={2} justify='center'>
        <Grid item xs={12}>
          <Grid container item spacing={2}>
            <Grid item sm={2} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                <b>{t('clinicBlocks.location')}</b>
              </Typography>
            </Grid>
            <Grid item sm={10} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                {company[0].location.streetAddress}
                {company[0].location.streetAddress2}
              </Typography>
            </Grid>
            <Grid item sm={2} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                <b>{t('clinicBlocks.city')}</b>
              </Typography>
            </Grid>
            <Grid item sm={10} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>{company[0].location.city}</Typography>
            </Grid>
            <Grid item sm={2} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                <b>{t('clinicBlocks.phone')}</b>
              </Typography>
            </Grid>
            <Grid item sm={10} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                <a href={`tel://${company[0].phoneNumber}`} target='_blank' rel='noopener noreferrer'>
                  {company[0].phoneNumber}
                </a>
              </Typography>
            </Grid>
            <Grid item sm={2} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                <b>{t('clinicBlocks.email')}</b>
              </Typography>
            </Grid>
            <Grid item sm={10} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                <a href={`mailto:${company[0].email}`} target='_blank' rel='noopener noreferrer'>
                  {company[0].email}
                </a>
              </Typography>
            </Grid>
            <Grid item sm={2} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                <b>{t('clinicBlocks.website')}</b>
              </Typography>
            </Grid>
            <Grid item sm={10} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                <a href={company[0].website} target='_blank' rel='noopener noreferrer'>
                  {company[0].website}
                </a>
              </Typography>
            </Grid>
            <Grid item xs={5}></Grid>
            <Grid item xs={2} align='center' className={classes.spacingTop}>
              <Divider />
            </Grid>
            <Grid item xs={12} align='center'>
              <ThermageButton variant='contained' color='primary' onClick={(e) => linkClinicToMyPromo(company[0].id, company[0])} className={classes.margin}>
                {t('buttons.select')}
              </ThermageButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

ClinicCodeLandingPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  //console.log('this is my state', state);
  return {
    auth: state.firebase.auth,
    user: state.firebase.profile,
    promos: state.firestore.ordered.promos,
    company: state.firestore.ordered.companies,
    companyError: state.company.companyError,
    companyMessage: state.company.companyMessage,
    companyPopUp: state.company.companyPopUp,
    companyErrorPopUp: state.company.companyErrorPopUp,
    selectedCompany: state.company.company,
    promoID: state.promos.promoID,
    userChecked: state.auth.userChecked,
    companyLogo: state.company.companyLogo,
    loadedLogo: state.company.loadedLogo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPromo: (promoID) => dispatch(setPromo(promoID)),
    addClinicToMyPromo: ({ userID, promoID, clinicID, user, company }) => dispatch(addClinicToMyPromo({ userID, promoID, clinicID, user, company })),
    closeCompanyPopUps: () => dispatch(closeCompanyPopUps()),
    registerLoggedinUsers: (user) => dispatch(registerLoggedinUsers(user)),
    getCompanyLogo: (url) => dispatch(getCompanyLogo(url)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(),
  firestoreConnect((props) => [{ collection: 'promos' }, { collection: 'companies', where: ['companyCode', '==', props.match.params.id] }])
)(ClinicCodeLandingPage);
